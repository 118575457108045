import Inko from 'inko';
import _ from 'lodash';

const inko = new Inko({ allowDoubleConsonant: true });

export const CommonUtil = {
  Unit: {
    format(unit) {
      const formatter = new Intl.NumberFormat('ko-KR');

      return formatter.format(unit ? Number(unit) : 0);
    },
  },
  Formatter: {
    /**
     * date 형식을 YYYYMMDD 문자열로 바꿔주는 함수
     * @params date
     */
    dateToString(dateString) {
      const dateObj = new Date(dateString);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    },
    /**
     * string 형식의날짜를 date 형식으로 바꿔주는 함수
     * @params YYYYMMDD
     */
    stringToDate(dateString) {
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
      const dateObject = new Date(year, month - 1, day);
      return dateObject;
    },
    /**
     * YYYYMMDD 형식의 문자열을 YYYY-MM-DD 형식의 문자열로 바꿔주는 함수
     */
    stringToDayForm(dateString) {
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
      return `${year}-${month}-${day}`;
    },
    // TODO : 더 좋은 formatting 방법 확인
    loginId(login = '') {
      let result = '';

      if (login !== '') {
        result = inko.ko2en(login);
        result = result.toLowerCase();
        result = result.replace(/[^a-zA-Z0-9]/g, '');
      }

      return result;
    },
    password(password = '') {
      let result = '';

      result = password;
      result = result.replace(/[^a-zA-Z0-9]/g, '');

      return result;
    },
    code(code = '') {
      let result = '';

      if (code !== '') {
        result = inko.ko2en(code);
        result = result.toUpperCase();
        result = result.replace(/[^a-zA-Z0-9]/g, '');
      }

      return result;
    },
    associationCode(associationCode = '') {
      let result = '';

      if (associationCode !== '') {
        result = inko.ko2en(associationCode);
        result = result.toUpperCase();
        result = result.replace(/[^a-zA-Z]/g, '');
        result = result.slice(0, 4);
      }

      return result;
    },
    enterpriseCode(enterpriseCode = '') {
      const length = enterpriseCode.length;
      let result = '';

      // if (enterpriseCode !== '') {
      //   result = inko.ko2en(enterpriseCode);
      //   result = result.toUpperCase();
      //   result = result.replace(/[^a-zA-Z0-9]/g, '');
      // }

      if (enterpriseCode !== '') {
        if (length === 1) {
          result = enterpriseCode.replace(/[^a-zA-Z]/g, '');
          result = result.toUpperCase();
        } else if (length < 4) {
          result = enterpriseCode[0].replace(/[^a-zA-Z]/g, '');
          result = result.toUpperCase();
          result += enterpriseCode.slice(1, 3).replace(/[^0-9]/g, '');
        } else {
          result = result.slice(0, 3);
        }
      }

      return result;
    },
    shopCode(shopCode = '') {
      const length = shopCode.length;
      let result = '';

      if (shopCode !== '') {
        if (length === 1) {
          result = shopCode.replace(/[^a-zA-Z]/g, '');
          result = result.toUpperCase();
        } else if (length < 4) {
          result = shopCode[0].replace(/[^a-zA-Z]/g, '');
          result = result.toUpperCase();
          result += shopCode.slice(1, 3).replace(/[^0-9]/g, '');
        } else {
          result = result.slice(0, 3);
        }
      }

      return result;
    },
    korean(val = '') {
      let result = '';
      if (val !== '') {
        result = val.replace(
          /[^ㄱ-ㅎㅏ-ㅣ가-힇a-zA-Z0-9.,!?_-ㆍᆢㆍ‥ㆍ ᆢㆍ ᆢ]/g,
          ''
        );
      }

      return result;
    },
    phone(phoneNumber = '') {
      const number = phoneNumber.replace(/[^0-9]/g, '');
      const length = number.length;

      let result = '';

      if (number.indexOf('02') === 0) {
        if (length <= 9) {
          result = number
            .slice(0, 10)
            .replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, '$1-$2-$3')
            .replace(/\-{1,2}$/g, '');
        } else {
          result = number
            .slice(0, 10)
            .replace(/^(\d{0,2})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
            .replace(/\-{1,2}$/g, '');
        }
      } else if (length <= 8) {
        result = number
          .slice(0, 8)
          .replace(/^(\d{0,4})(\d{0,4})$/g, '$1-$2')
          .replace(/\-{1,2}$/g, '');
      } else if (length <= 10) {
        result = number
          .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, '$1-$2-$3')
          .replace(/\-{1,2}$/g, '');
      } else if (length <= 11) {
        result = number
          .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
          .replace(/\-{1,2}$/g, '');
      } else {
        result = number
          .slice(0, 11)
          .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
          .replace(/\-{1,2}$/g, '');
      }

      return result;
    },
    fax(fax = '') {
      const number = fax.replace(/[^0-9]/g, '');
      const length = number.length;
      let result = '';

      if (length <= 4) {
        return number;
      } else if (number.indexOf('0504') === 0) {
        result = number
          .slice(0, 12)
          .replace(/^(\d{0,4})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
          .replace(/\-{1,2}$/g, '');
        return result;
      } else if (number.indexOf('02') === 0) {
        if (number.length <= 9) {
          result = number
            .slice(0, 10)
            .replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, '$1-$2-$3')
            .replace(/\-{1,2}$/g, '');
        } else {
          result = number
            .slice(0, 10)
            .replace(/^(\d{0,2})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
            .replace(/\-{1,2}$/g, '');
        }
      } else if (length <= 10) {
        result = number
          .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, '$1-$2-$3')
          .replace(/\-{1,2}$/g, '');
      } else if (length <= 11) {
        result = number
          .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
          .replace(/\-{1,2}$/g, '');
      } else {
        result = number
          .slice(0, 11)
          .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
          .replace(/\-{1,2}$/g, '');
      }

      return result;
    },
    cellphone(cellPhoneNumber = '') {
      const number = cellPhoneNumber.replace(/[^0-9]/g, '');
      const length = number.length;
      let result = '';

      if (length <= 3) {
        return number;
      } else if (length <= 10) {
        result = number
          .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, '$1-$2-$3')
          .replace(/\-{1,2}$/g, '');
      } else if (length <= 11) {
        result = number
          .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
          .replace(/\-{1,2}$/g, '');
      } else {
        result = number
          .slice(0, 11)
          .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
          .replace(/\-{1,2}$/g, '');
      }

      return result;
    },
    email(emailAddress = '') {
      let result = '';

      if (emailAddress !== '') {
        result = inko.ko2en(emailAddress);
        result = result.replace(/[^a-zA-Z0-9@\-_.]/g, '');
      }

      return result;
    },
    biz(bizNumber = '') {
      const number = bizNumber.replace(/[^0-9]/g, '');
      const length = number.length;
      let result = '';

      if (length < 4) {
        return number;
      } else if (length < 7) {
        result += number.slice(0, 3);
        result += '-';
        result += number.slice(3);
      } else {
        result += number.slice(0, 3);
        result += '-';
        result += number.slice(3, 5);
        result += '-';
        result += number.slice(5, 10);
      }
      return result;
    },
    corporateBiz(corporateBiz = '') {
      const number = corporateBiz.replace(/[^0-9]/g, '');
      const length = number.length;
      let result = '';

      if (length < 7) {
        return number;
      } else {
        result += number.slice(0, 6);
        result += '-';
        result += number.slice(6, 13);
      }

      return result;
    },
    vin(vin = '') {
      const formatted = vin.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
      const length = formatted.length;
      let result = '';

      result = formatted;

      if (length > 11) {
        result = result.slice(0, 11);
      }

      return result;
    },
    vinRemain(vinRemain = '') {
      const formatted = vinRemain.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
      const length = formatted.length;
      let result = '';

      result = formatted;

      if (length > 6) {
        result = result.slice(0, 6);
      }

      return result;
    },
    trvlDistance(trvlDistance = 0) {
      // 최대 입력값 9999999
      if (typeof trvlDistance === 'string') {
        const number = trvlDistance.replace(/[^0-9]/g, '');
        let result = 0;
        const max = 9999999;

        result = Number(number);

        if (result > max) result = max;

        return result;
      }
      let result = 0;
      const max = 9999999;

      result = trvlDistance;

      if (result > max) result = max;

      return result;
    },
  },
  Validator: {
    // password(password = '') {
    //   const regex =
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    //   return (
    //     password !== undefined && password.trim() !== '' && regex.test(password)
    //   );
    // },
    password(password = '') {
      // const regex =
      //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
      const regex = /^[a-z0-9]{4,}$/;
      return (
        password !== undefined && password.trim() !== '' && regex.test(password)
      );
    },
    phone(phoneNumber = '') {
      const regex =
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})-([0-9]{3,4})-([0-9]{4})$/;
      return (
        phoneNumber !== undefined &&
        phoneNumber.trim() !== '' &&
        regex.test(phoneNumber)
      );
    },
    cellphone(cellPhoneNumber = '') {
      const regex = /^01([016789])-([0-9]{3,4})-([0-9]{4})$/;
      return (
        cellPhoneNumber !== undefined &&
        cellPhoneNumber.trim() !== '' &&
        regex.test(cellPhoneNumber)
      );
    },
    email(emailAddress = '') {
      const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
      return emailAddress !== '' && regex.test(emailAddress);
    },
    licensePlateNumber(licensePlateNumber = '') {
      if (licensePlateNumber.length > 9) return false;

      if (
        licensePlateNumber.length === 8 &&
        /^[가-힣]{2}\d{1}[가-힣]\d{4}/.test(licensePlateNumber)
      )
        return true;
      if (
        licensePlateNumber.length === 9 &&
        /^[가-힣]{2}\d{2}[가-힣]\d{4}/.test(licensePlateNumber)
      )
        return true;
      if (
        licensePlateNumber.length === 7 &&
        /^\d{2}[가-힣]\d{4}/.test(licensePlateNumber)
      )
        return true;
      if (
        licensePlateNumber.length === 8 &&
        /^\d{3}[가-힣]\d{4}/.test(licensePlateNumber)
      )
        return true;

      return false;
    },
  },
  Pattern: {
    login: /[a-z0-9]$/,
    password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,}$/,
    associationCode: /^[A-Z]{4}$/,
    enterpriseCode: /[a-zA-Z]\d{2}$/,
    shopCode: /[a-zA-Z]\d{2}$/,
    phone: /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})-([0-9]{3,4})-([0-9]{4})$/,
    cellphone: /^(\d{3})-?(\d{3,4})-?(\d{4})$/,
    biz: /^(\d{3})-?(\d{2})-?(\d{5})$/,
    corporateBiz: /^(\d{6})-?(\d{7})$/,
    email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})$/,
    statementNumber: /([0-9]{2})$/,
  },
  Window: {
    popup: (url, title, w = 0, h = 0) => {
      // 화면의 가로 및 세로 크기를 얻기
      const screenWidth = window.screen.availWidth;
      const screenHeight = window.screen.availHeight;

      // 팝업 창의 크기 설정
      const popupWidth = Math.min(screenWidth, w);
      const popupHeight = Math.min(screenHeight, h);

      // 팝업 창을 화면 가운데로 위치시키기
      const left =
        (screenWidth - popupWidth) / 2 + window.screenX || window.screenLeft;
      const top =
        (screenHeight - popupHeight) / 2 + window.screenY || window.screenTop;

      // console.log('[POP UP LOCATION]', left, top);
      const popupWindow = window.open(
        url,
        title,
        `width=${w}, height=${h}, top=${top}, left=${left}, menubar=no, toolbar=no, location=no, status=no`
      );

      // window 객체에 만들어진 popups 에 popup 넣어두기

      window.cerp.popups.current.push(popupWindow);

      if (popupWindow) {
        popupWindow.focus();
      }
    },
  },
  File: {
    convertForServer(clientData) {
      const fileData = [];
      const files = [];

      _.map(clientData, (item) => {
        const { seq = null, state, file } = item;
        // console.log(item);
        fileData.push({ seq, state });
        files.push(file || new File([], 'emptyFile.cerp'));
      });

      return { fileData, files };
    },
    /**
     * 사고관리에서 사용하는 이미지 파일 변환
     */
    convertForServerClaim(fileItmes) {
      const fileData = [];
      const files = [];

      _.map(fileItmes, (item) => {
        const { documentCode, imgId, state, file } = fileItmes;
        fileData.push({ documentCode, imgId, state: state });
        files.push(file || new File([], 'emptyFile.cerp'));
      });

      console.log(fileData, files);
      return { fileData, files };
    },
    convertForServerClaimPartner(fileItmes) {
      const fileData = [];
      const files = [];

      _.map(fileItmes, (item) => {
        const { documentCode, state, file } = item;
        fileData.push({ documentCode, state: state });
        files.push(file || new File([], 'emptyFile.cerp'));
      });

      console.log(fileData, files);
      return { fileData, files };
    },
  },
};
